import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled, { css } from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import AllNYUOverlay from "./AllNYUOverlay";
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import Hero from "./Hero";
import SearchBar from "./SearchBar";

type pageData = {
    id: string;
    title: string;
    uri: string;
    content: string;
    featuredImage: {
        node: {
            altText: string;
            gatsbyImage: IGatsbyImageData;
        };
    };
    template: {
        templateName: string;
    };
    wpChildren: {
        nodes: {
            id: string;
        }[];
    };
    menuOrder: number;
};

const Header = ({ path }: { path: string }) => {
    const data = useStaticQuery(graphql`
        query {
            allWpPage {
                nodes {
                    id
                    title
                    uri
                    content
                    featuredImage {
                        node {
                            altText
                            gatsbyImage(width: 1000)
                        }
                    }
                    template {
                        templateName
                    }
                    wpChildren {
                        nodes {
                            id
                        }
                    }
                    menuOrder
                }
            }
        }
    `) as { allWpPage: { nodes: pageData[] } };

    // process.env.NODE_ENV == "development" ? console.log(data) : null;

    const menuItemNames = new Set(["RITS Home", "Space Booking", "News", "Contact", "About RITS"]);

    const menuData = data.allWpPage.nodes.filter((page) =>
        menuItemNames.has(page.title)
    ) as pageData[];

    // Note: pretty dirty stuff happends here, I know, sorry. It works though =)
    // // Add "RITS Home" option to the menu
    // menuData.push({
    //     id: "rits-home",
    //     title: "RITS Home",
    //     uri: "/",
    //     content: "",
    //     featuredImage: {
    //         node: {
    //             altText: "",
    //             gatsbyImage: { layout: "constrained", images: {}, width: 0, height: 0 },
    //         },
    //     },
    //     template: {
    //         templateName: "default",
    //     },
    //     wpChildren: {
    //         nodes: [],
    //     },
    //     menuOrder: 0,
    // });

    const spaceBookingPage = data.allWpPage.nodes.find((page) => page.title === "Space Booking");
    if (spaceBookingPage) {
        spaceBookingPage.uri = "https://library.shanghai.nyu.edu/rooms-and-spaces";
    }

    type menuTreeType = {
        id: string;
        title: string;
        link: string;
        menuOrder: number;
        children: menuTreeType | null;
    }[];

    const menuDataSorted = menuData.sort((a, b) => a.menuOrder - b.menuOrder);

    const menuTreeFunction = (menuData: pageData[]) => {
        const tree = [] as menuTreeType;
        for (const page of menuData) {
            // console.log("page: ", page);

            const childrenIds =
                page.wpChildren && page.wpChildren.nodes.length > 0
                    ? page.wpChildren.nodes.map((item) => item.id)
                    : null;

            const childrenElements = childrenIds
                ? data.allWpPage.nodes.filter((page) => childrenIds.includes(page.id))
                : null;

            tree.push({
                id: page.id,
                title: page.title,
                link: page.uri,
                menuOrder: page.menuOrder,
                children: childrenElements ? menuTreeFunction(childrenElements) : null,
            });
        }
        const sortedTree = tree.sort((a, b) => a.menuOrder - b.menuOrder);
        return sortedTree;
    };

    const menuTree = menuTreeFunction(menuDataSorted);

    const menuCreatorFunction = (menuTree: menuTreeType) => {
        return menuTree.map((item) => {
            return (
                <NavMenuItem key={item.id}>
                    <NavMenuItemLink to={item.link}>
                        {item.title}
                        {item.children && item.children.length > 0 ? (
                            <span>
                                <FaAngleDown />
                            </span>
                        ) : null}
                    </NavMenuItemLink>

                    {item.children && item.children.length > 0 ? (
                        <NavMenuList className="children">
                            {menuCreatorFunction(item.children)}
                        </NavMenuList>
                    ) : null}
                </NavMenuItem>
            );
        });
    };

    const menuTreeElement = menuCreatorFunction(menuTree);

    const [isOpen, setIsOpen] = React.useState(false);

    const NavHamburgerClickHandler = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        return;
    };

    // Get current page
    // console.log(path);

    let heroContent = "";
    if (path === "/") {
        // console.log(data.allWpPage.nodes.find((page) => page.uri === "/"));
        heroContent = data.allWpPage.nodes.find((page) => page.uri === "/")?.content || "";
    }

    // Get the featured image of the current page
    const featuredImageQuery = data.allWpPage.nodes.find(
        (page) => page.uri === path
    )?.featuredImage;
    const featuredImageNode = featuredImageQuery ? featuredImageQuery.node : null;
    const featuredImage = featuredImageNode
        ? { image: featuredImageNode.gatsbyImage, altText: featuredImageNode.altText }
        : null;

    return (
        <>
            <HeaderBg className={path === "/" ? "index" : ""}>
                {featuredImage && (
                    <HeroImage
                        className={path === "/" ? "index" : ""}
                        image={featuredImage?.image}
                        alt={featuredImage?.altText}
                    />
                )}

                <TopBar>
                    <TopBarCont>
                        <SearchBar />
                        <AllNYUOverlay />
                    </TopBarCont>
                </TopBar>
                <HeaderCont>
                    <Nav className={isOpen ? "open" : ""}>
                        <NavLogo to="/">
                            <NavLogoImageContainer>
                                <StaticImage
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                    src="../../../images/purple.png"
                                    alt="NYUSH Library Logo"
                                />
                            </NavLogoImageContainer>
                        </NavLogo>
                        <NavHamburgerCont>
                            <NavHamburger
                                onClick={NavHamburgerClickHandler}
                                className={isOpen ? "open" : ""}
                            >
                                <NavHamburgerTopBar />
                                <NavHamburgerBottomBar />
                            </NavHamburger>
                        </NavHamburgerCont>
                        <NavMenu className={isOpen ? "open" : ""}>
                            <NavMenuList className="root">
                                {/* <NavMenuItem key="home">
                                <NavMenuItemLink to="/">Home</NavMenuItemLink>
                            </NavMenuItem> */}
                                {menuTreeElement}
                            </NavMenuList>
                        </NavMenu>
                    </Nav>
                </HeaderCont>
            </HeaderBg>
            {path === "/" ? <Hero content={heroContent} /> : null}
        </>
    );
};

export default Header;

const HeroImage = styled(GatsbyImage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    overflow: hidden;

    height: 200px;
    &.index {
        height: 493px;
    }

    @media screen and (max-width: 1038px) {
        height: 160px;
    }
`;

const NavMenuItemLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 0.5rem 0 0.5rem 0;
    display: block;
    border-bottom: solid 2px transparent;
`;

const NavMenuItem = styled.li`
    margin-left: 1rem;
    list-style: none;
    line-height: 1.5em;
    flex-grow: 0;
    flex-shrink: 1;

    &:last-child .children {
        right: 0;
    }
`;

const NavMenuList = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 80%;
    @media (min-width: 62rem) {
        flex-direction: row;
        font-size: 100%;
    }

    @media (min-width: 62rem) {
        flex-direction: row;
    }

    &.root > ${NavMenuItem} > ${NavMenuItemLink}:hover {
        border-bottom: solid 2px white;
    }

    &.children {
        padding: 0;
        border: 0;
        border-radius: 0;
        display: none;
        background-color: #fff;
        background-clip: padding-box;
        min-width: 160px;
        border-top: 5px solid transparent;
        box-shadow: 0px 8px 8px 0px rgb(0 0 0 / 75%);
        position: absolute;
        // top: 100%;
        z-index: 10;
        width: 10vw;
        min-width: 10rem;
        margin: 0;
    }

    @media screen and (max-width: 1038px) {
        font-size: 24px;
        flex-direction: column;

        &.children {
            display: none;
            background: none;
            width: 100%;
            box-shadow: none;
        }

        &.children ${NavMenuItemLink} {
            color: white !important;
        }
    }

    &.children ${NavMenuItemLink} {
        color: black;
        padding: 0;
    }

    &.children ul {
        right: auto;
        border-top: 10px solid transparent;
        box-shadow: 0px 12px 8px 0px rgb(0 0 0 / 75%);
    }

    @media screen and (max-width: 1038px) {
        &.children ul {
            box-shadow: none;
        }
    }

    &.children ${NavMenuItem} {
        padding: 0.375rem 0.9375rem;
        margin-left: 0;
    }

    &.children ${NavMenuItem}:hover {
        background: #57068c;
    }

    &.children ${NavMenuItem}:hover > ${NavMenuItemLink} {
        color: white;
    }

    &.children .children {
        right: 100%;
        border-top: 0;
        left: auto;
        position: absolute;
        margin-top: -4em;
        padding-top: 5px;
        width: 10vw;
        z-index: 10;
    }

    ${NavMenuItem}:hover > & {
        display: block;
    }
`;

const NavHamburgerBarStyle = css`
    content: "";
    display: block;
    position: absolute;
    left: 15px;
    width: 22px;
    height: 2px;
    background: #fff;
    transform: rotate(0);
    transition: all 0.25s;
`;

const NavHamburgerTopBar = styled.span`
    ${NavHamburgerBarStyle}
    top: 23px;
    transform: translateY(-3px);
`;

const NavHamburgerBottomBar = styled.span`
    ${NavHamburgerBarStyle}
    bottom: 23px;
    transform: translateY(3px);
`;

const NavHamburger = styled.div`
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    transition: all 0.25s;

    &:hover ${NavHamburgerTopBar} ${NavHamburgerBottomBar} {
        background: #999;
    }

    @media screen and (max-width: 1038px) {
        &.open {
            transform: rotate(90deg);
        }

        &.open ${NavHamburgerTopBar} {
            transform: rotate(45deg) translateY(0px);
        }

        &.open ${NavHamburgerBottomBar} {
            transform: rotate(-45deg) translateY(0px);
        }
    }
`;

const NavHamburgerCont = styled.div`
    z-index: 150;
    display: none;
    float: right;
    width: 48px;
    align-items: center;

    @media screen and (max-width: 1038px) {
        display: flex;
    }
`;

const NavLogoImageContainer = styled.div`
    top: 0;
    display: "center";
`;

import hero_banner from "../../../images/hero_banner.png";
const HeaderBg = styled.div`
    background-image: url(${hero_banner});
    background-size: cover;
    background-position: center;
    height: 200px;
    &.index {
        height: 493px;
    }
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    @media screen and (max-width: 1038px) {
        height: 160px;
    }
`;

const HeaderCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    z-index: 1;

    @media screen and (max-width: 1440px) {
        max-width: 1120px;
    }

    @media screen and (max-width: 768px) {
        height: 50px;
        max-width: 1120px;
    }
`;

const TopBar = styled.nav`
    height: 36px;
    display: flex;
    margin: 0;
    justify-content: right;
    z-index: 100;
    position: relative;
    color: #656565;
    background: white;
`;

const TopBarCont = styled.div`
    max-width: 1390px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1440px) {
        max-width: 1120px;
    }
`;

const Nav = styled.nav`
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    z-index: 100;
    width: 100%;
    position: relative;
    transition: all 0.25s;

    @media screen and (max-width: 1038px) {
        &.open {
            background-color: rgba(0, 0, 0, 0.5);
            height: auto;
        }
    }

    position: relative;
    padding: 15px 0px 90px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
`;

const NavLogo = styled(Link)`
    z-index: 150;
    display: flex;
    float: left;
    max-width: 400px;
    max-height: 75px;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1038px) {
        max-width: 180px;
    }
`;

const NavMenu = styled.div`
    display:flex;
    flex-basis: auto;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-right: 3.5rem;
    max-width: calc(100% - 222px);
    flex-wrap: nowrap;
    @media screen and (max-width:1440px) {
    font-size: 0.8em;
    }
    @media screen and (max-width:1200px) {
    font-size: 0.8em;
    }
    @media screen and (max-width:1038px){
    display: none;

    &.open {
        display: flex;
        flex-basis: 100%;
        width: 100%;
    }

    &.open > ${NavMenuList} {
        width: 80%;
        margin: auto;
    }

    &.open ${NavMenuList}.children {
        position: inherit;

    }
`;
